<template>
  <div>
   <v-img width="100%" :height="$vuetify.breakpoint.xlOnly ? 800 : $vuetify.breakpoint.lgOnly  ? 800 : ''"
      src="../../assets/images/feature/meet.png"
      class="d-flex align-center img-gradient transparent-box img-gradient-overlay-demo"
      :class="$vuetify.breakpoint.lgAndUp ? '': ''">
      <v-container class="apply-index"  :class="$vuetify.breakpoint.lgAndUp ? [ 'common-width ']: ''">
        <template v-for="(item, index) in getdemoObj.demo">
          <v-container :key="index">
            <v-row justify="center" :class="$vuetify.breakpoint.xsOnly ? 'make-column' : ''" >
              <v-col xs="12" lg="5" xl="6" md="6" sm="8">
                <v-container>
                  <h1 class="white--text font-weight-bold text-left" :class="$vuetify.breakpoint.smAndUp  ? 'text-h2':' text-h6'">{{ item.title }}</h1>
                  <p class=" white--text text-subtitle-1 text-left apply-text mt-4"> {{ item.description }}</p>
                </v-container>
              </v-col>
              <v-col xs="12" lg="7" xl="5" md="6" sm="8" v-if="saveForm">
                <v-container>
                  <v-form ref="demoForm">
                    <v-row class="mx-2" justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'make-column' : ''">
                      <v-col xs="12" lg="6" xl="6" md="12" sm="12" class="pa-1">
                        <span class="white--text">Name</span>
                        <v-text-field dense background-color="#ffffff"
                          v-model="demoObj.name" hide-details
                          :rules="rules"  outlined>
                        </v-text-field>
                      </v-col>
                      <v-col xs="12" lg="6" xl="6" md="12" sm="12" class="pa-1">
                        <span class="white--text">Mobile</span>
                        <v-text-field dense hide-details background-color="#ffffff" v-model="demoObj.mobile"
                          :rules="$_phoneNumberValidation" outlined >
                        </v-text-field>
                      </v-col>
                      <v-col xs="12" lg="12" xl="12" md="12" sm="12" class="pa-1">
                        <span class="white--text">Email</span>
                        <v-text-field dense background-color="#ffffff" hide-details v-model="demoObj.email"
                          :rules="emailrules"  outlined ></v-text-field>
                      </v-col>
                       <v-col xs="12" lg="12" xl="12" md="12" sm="12" class="pa-1">
                        <span class="white--text">Company / Busniess</span>
                        <v-text-field dense hide-details background-color="#ffffff" v-model="demoObj.company" :rules="rules"
                          outlined >
                        </v-text-field>
                      </v-col>
                      <v-col xs="12" lg="12" xl="12" md="12" sm="12">
                        <span class="white--text">Preferable date</span>
                          <v-menu v-model="demoObj.startDateTimeMenu" :close-on-content-click="false"
                            :nudge-right="40" :nudge-top="50" transition="scale-transition"
                            min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field background-color="#ffffff" slot="activator"
                              v-model="demoObj.start_date" outlined
                              dense hide-details readonly :rules="rules">
                              <template v-slot:prepend>
                                <v-icon color="#ffffff" @click="on.click" size="25">mdi-timetable</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-tabs v-model="demoObj.startDateActive" color="primary lighten-1" dark centered slider-color="white"
                              height="37"
                             :key="`menu${reInitDateTimePicker}`">
                            <v-tab ripple>
                              <v-icon color="white" class="pr-2">mdi-calendar</v-icon>
                            </v-tab>
                            <v-tab ripple>
                              <v-icon color="white" class="pr-2">mdi-clock-outline</v-icon>
                            </v-tab>
                            <v-tab-item>
                              <v-date-picker class="border-textfield"  v-model="demoObj.startDate"
                               color="#5E35B1"
                               no-title
                               @change="startDateTimeHandler()"
                               :first-day-of-week="1"
                               :min="new Date().toISOString().substr(0, 10)"
                               >
                            </v-date-picker>
                            </v-tab-item>
                            <v-tab-item>
                              <v-card flat>
                                <v-time-picker
                                  v-model="startTimePicker"
                                  color="#5E35B1"
                                  ampm-in-title
                                  @change="startDateTimePickerHandler()"
                                  @click:hour="startDateTimePickerHandler()"
                                  @update:period="updatePeriodWithStartTime"
                                >
                                </v-time-picker>
                              </v-card>
                            </v-tab-item>
                          </v-tabs>
                        </v-menu>
                      </v-col>
                      <v-col xs="12" lg="12" xl="12" md="12" sm="12">
                        <!-- <span class="white--text">End date</span> -->
                        <v-menu v-model="demoObj.endDateTimeMenu" :close-on-content-click="false"
                          :nudge-right="40" :nudge-top="50"
                          transition="scale-transition" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field background-color="#ffffff" slot="activator"
                              v-model="demoObj.end_date" outlined
                              dense hide-details readonly :rules="rules">
                              <template v-slot:prepend>
                                <v-icon color="#ffffff" @click="on.click" size="25">mdi-timetable</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-tabs v-model="demoObj.endDateActive" color="#5E35B1"
                            dark centered slider-color="white" height="37"
                            :key="`menu${reInitDateTimePicker}`">
                            <v-tab ripple>
                              <v-icon color="white" class="pr-2">mdi-calendar</v-icon>
                            </v-tab>
                            <v-tab ripple>
                              <v-icon color="white" class="pr-2">mdi-clock-outline</v-icon>
                            </v-tab>
                            <v-tab-item>
                              <v-date-picker v-model="demoObj.endDate" color="#5E35B1"
                               no-title @change="endDateTimeHandler()"
                              :first-day-of-week="1" :min="demoObj.startDate"
                              :allowed-dates="allowedDates"
                              ></v-date-picker>
                            </v-tab-item>
                            <v-tab-item>
                              <v-card flat>
                               <v-time-picker :close-on-content-click="false"
                                v-model="endTimePicker" color="#5E35B1"
                                ampm-in-title
                                @change="endDateTimePickerHandler()"
                                 @click:hour="endDateTimePickerHandler()"
                                @update:period="updatePeriodWithEndTime">
                                </v-time-picker>
                              </v-card>
                            </v-tab-item>
                          </v-tabs>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" class="pa-1">
                        <span class="white--text">Comment</span>
                        <v-textarea dense background-color="#ffffff" v-model="demoObj.message"
                          hide-details outlined ></v-textarea>
                          <!-- <div class="g-recaptcha" :data-sitekey="key" ref="recaptcha"></div> -->
                      </v-col>
                      <v-col cols="12" class="mb-3">
                         <div id="recaptcha-main" class="g-recaptcha" :data-sitekey="siteKey" ref="recaptcha"></div>
                         <!-- <div class="g-recaptcha" :data-sitekey="siteKey"> -->
                      </v-col>
                      <v-col cols="12" class="pa-1">
                        <v-row class="pa-0" justify="center">
                          <v-btn :width="$vuetify.breakpoint.lgAndUp ? 190 : 150"
                            @click="sendMessage()"
                            class="rounded-pill white--text mb-4 ml-1 pa-6 font-weight-bold
                            apply-gradient-demo no-text-transform"
                            :samll=" $vuetify.breakpoint.mdAndDown"
                            :x-large=" $vuetify.breakpoint.lgAndUp"
                            :loading="isButtonLoading"> Submit
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-col>
              <template v-else>
                <v-col xs="12" lg="6" xl="4" md="6" sm="8" v-for="(item, index) in getdemoObj.demo_content"
                  :key="`content-${index}`" >
                  <v-container class="d-flex justify-center">
                    <v-row justify="center">
                      <v-col cols="12" class="d-flex justify-center">
                        <v-icon color="success" size="70">mdi-checkbox-marked-circle </v-icon>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <p class="text-center white--text font-weight-bold text-h4">{{ item.title }}</p>
                      </v-col>
                      <v-col cols="12">
                        <p class="text-center white--text">{{ item.description }}</p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </template>
      </v-container>
   </v-img>
    <v-snackbar v-model="snackBarObj.snackbar" timeout="2000" top right :color="snackBarObj.color">
        <v-alert class="pa-2 mb-0 white--text" :color="snackBarObj.color"> {{ snackBarObj.message }} </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import { api } from '../../plugins/axios'
import moment from 'moment'
export default {
  data () {
    return {
      loadCaptcha: false,
      demoObj: {
        message: 'Demo',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      showCard: false,
      siteKey: process.env.VUE_APP_YOUR_SITE_KEY,
      rules: [v => !!v || 'Required'],
      emailrules: [v => (!!v && /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(v)) || 'Email required'],
      phoneRules: [
        v => !!v || 'Required Field',
        v => /^[0-9]{10}$/.test(v) || 'Phone must be valid'
      ],
      reInitDateTimePicker: 0,
      getdemoObj: {},
      isButtonLoading: false,
      startTimePicker: '',
      endTimePicker: '',
      snackbar: false,
      snackBarObj: {
        snackbar: false
      },
      saveForm: true
    }
  },
  created () {
    try {
      setTimeout(() => {
        window.grecaptcha.render('recaptcha-main') // 'recaptcha-main' is the id that was assigned to the widget
      }, 2000)
    } catch {
      console.log('recapcha not loaded')
    }
  },
  mounted () {
    this.loadScript('https://www.google.com/recaptcha/api.js', (cScript) => {
      console.log(`Cool, the script ${cScript.src} is loaded`)
      this.loadCaptcha = true
    })
    // this.loadScript('https://www.google.com/recaptcha/enterprise.js', (cScript) => {
    //   console.log(`Cool, the script ${cScript.src} is loaded`)
    //   this.loadCaptcha = true
    // })
    this.getDemo()
    this.scrollToTop()
  },
  computed: {
    $_phoneNumberValidation () {
      return [
        v => !!v || 'Required Field',
        v => /^[0-9]{10}$/.test(v) || 'Phone must be valid'
      ]
    }
  },
  methods: {
    loadScript (src, callback) {
      const cScript = document.createElement('script')
      cScript.src = src
      cScript.onload = () => callback(cScript)
      document.head.append(cScript)
    },
    sendMessage () {
      var response = window.grecaptcha.getResponse()
      if (this.$refs.demoForm[0].validate() && response) {
      // if (this.$refs.demoForm[0].validate()) {
        this.isButtonLoading = true
        const model = this.demoObj
        api.post('/send-message', model).then(() => {
          this.isButtonLoading = false
          this.demoObj = {}
          this.clearDemoObj()
          this.$refs.demoForm[0].reset()
          this.snackBarObj = { snackbar: true, color: 'success', message: 'Congratulations! demo has been booked successfully.' }
          this.saveForm = false
          setTimeout(() => {
            this.saveForm = true
          }, 15000)
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.snackBarObj = { snackbar: true, color: 'error', message: 'Sorry, this user has already been booked. Please try again on another day.' }
            this.demoObj = {}
            this.clearDemoObj()
            this.$refs.demoForm[0].reset()
            this.isButtonLoading = false
          } else if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
            this.demoObj = {}
            this.clearDemoObj()
            this.$refs.demoForm[0].reset()
          }
        })
        // this.snackBarObj = { snackbar: true, color: 'success', message: 'Submitted successfully!' }
      } else {
        this.snackBarObj = { snackbar: true, color: 'error', message: 'Please check the required fields or check the reCAPTCHA' }
      }
    },
    getDemo () {
      api.get('/demo')
        .then((response) => {
          if (response.data) {
            this.getdemoObj = response.data
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
          console.log(error.message)
        })
    },
    startDateTimeHandler () {
      this.reInitDateTimePicker++
      this.$set(this.demoObj, 'startDateActive', 1)
      if (this.startTimePicker) this.assignSameDateToEndDate()
      this.startTimePicker = ''
    },
    endDateTimeHandler () {
      this.reInitDateTimePicker++
      this.$set(this.demoObj, 'endDateActive', 1)
      this.endTimePicker = ''
    },
    startDateTimePickerHandler () {
      if (this.demoObj.startDate && this.startTimePicker) {
        this.$set(this.demoObj, 'startDateTimeMenu', false)
        const date = this.formatDate(`${this.demoObj.startDate} ${this.startTimePicker}`, 'YYYY-MM-DD HH:mm', 'DD.MM.YYYY HH.mm')
        const setDate = this.timePickerConvertAMPM(date)
        this.$set(this.demoObj, 'start_date', setDate)
        this.assignSameDateToEndDate()
      } else {
        this.snackBarObj = { snackbar: true, color: 'error', message: 'Please select hour & minutes' }
      }
    },
    endDateTimePickerHandler () {
      if (this.demoObj.endDate && this.endTimePicker) {
        this.$set(this.demoObj, 'endDateTimeMenu', false)
        const date = this.formatDate(`${this.demoObj.endDate} ${this.endTimePicker}`, 'YYYY-MM-DD HH:mm', 'DD.MM.YYYY HH.mm')
        const setDate = this.timePickerConvertAMPM(date)
        this.$set(this.demoObj, 'end_date', setDate)
      } else {
        this.snackBarObj = { snackbar: true, color: 'error', message: 'Please select and hour & minutes' }
      }
    },
    timePickerConvertAMPM (dateTime) {
      if (dateTime) {
        const getStartTime = dateTime.split(' ')[1]
        const getTime = getStartTime.split('.')
        // Get hours and minutes from selected time
        const hours = getTime[0]
        const minutes = getTime[1]
        // Convert hours to 12-hour format and determine AM/PM
        const amPm = hours >= 12 ? 'PM' : 'AM'
        let displayHours = hours % 12
        displayHours = displayHours || 12 // Corrected syntax
        // Format minutes to ensure double digits
        // const displayMinutes = minutes < 10 ? '0' + minutes : minutes

        // Construct the formatted time string
        const formattedTime = `${displayHours}:${minutes} ${amPm}`
        const getDate = dateTime.split(' ')[0]
        return `${getDate} ${formattedTime}`
      }
    },
    updatePeriodWithEndTime (time) {
      this.$set(this.demoObj, 'endDateTimeMenu', false)
      const date = this.formatDate(`${this.demoObj.endDate} ${this.endTimePicker}`, 'YYYY-MM-DD HH:mm', 'DD.MM.YYYY HH.mm')
      const setDate = this.timePickerConvertAMPM(date)
      const updateSameTimeAMPM = `${setDate.split(' ')[0]} ${setDate.split(' ')[1]} ${time.toUpperCase()}`
      this.$set(this.demoObj, 'end_date', updateSameTimeAMPM)
    },
    updatePeriodWithStartTime (time) {
      this.$set(this.demoObj, 'startDateTimeMenu', false)
      const date = this.formatDate(`${this.demoObj.startDate} ${this.startTimePicker}`, 'YYYY-MM-DD HH:mm', 'DD.MM.YYYY HH.mm')
      const setDate = this.timePickerConvertAMPM(date)
      const updateSameTimeAMPM = `${setDate.split(' ')[0]} ${setDate.split(' ')[1]} ${time.toUpperCase()}`
      this.$set(this.demoObj, 'start_date', updateSameTimeAMPM)
      this.$set(this.demoObj, 'end_date', updateSameTimeAMPM)
    },
    formatDate (date, fromFormat, toFormat) {
      if (!date) return null
      return moment(date, (fromFormat || 'DD.MM.YYYYTHH.mm.ss')).isValid() ? moment(date, (fromFormat || 'DD.MM.YYYYTHH.mm.ss')).format(toFormat || 'DD.MM.YYYY HH:mm') : null
    },
    allowedDates: (val) => {
      const currentDate = new Date().toISOString().split('T')[0]
      return val >= currentDate
    },
    assignSameDateToEndDate () {
      this.demoObj.endDate = this.demoObj.startDate
      this.endTimePicker = this.addOneHour(this.startTimePicker)
      this.endDateTimePickerHandler()
    },
    addOneHour (getTime) {
      // Parse the time string into a moment object
      const endTime = moment(getTime, 'HH:mm')
      // Add one hour to the moment object
      endTime.add(1, 'hours')

      // Format the updated time as 'HH:mm' string
      const newEndTime = endTime.format('HH:mm')
      return newEndTime
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    },
    clearDemoObj () {
      this.demoObj.message = 'Demo'
      this.demoObj.startDate = moment().format('YYYY-MM-DD')
      this.demoObj.endDate = moment().format('YYYY-MM-DD')
    }
  }
}
</script>

<style>

.img-gradient-overlay-demo {
  position:relative;
}
.img-gradient-overlay-demo::after {
  content: '';
  position: absolute !important;
  left: 0 !important;
   top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(to top, rgba(1, 2, 7, 0.461),rgba(15, 15, 134, 0.6)) !important;
}
.border-textfield {
 background: #ffffff;
  border:0 solid white !important;
}
.apply-gradient-demo {
      background: linear-gradient(to right, #7E57C2, #FF7C82);
      color: #000000;
}
.glass {
  background: linear-gradient(135deg, rgba(221, 214, 214, 0.7),rgba(222, 209, 209, 0.3)) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter:blur(10px) !important ;
  color: #ffffff !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37) !important;
   border-radius: 13px !important
}
</style>
